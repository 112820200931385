import {
  TextField,
  Button,
  div,
  useTheme,
  useMediaQuery,
  CardMedia
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import "./inquiry.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { sendArtInquiryAsync } from "../../redux/ArtSlice";
import LoadingSpinner from "../circularProgress/LoadingSpinner";
import { saveInquiryArt } from "../../redux/userSlice";
import '../../global.css';

function Inquiry() {
  // const { id } = useParams();
  // const sitekey = process.env.REACT_APP_SITEKEY1;
  // console.log("ReCAPTCHA Site Key:", sitekey);
  // const recaptchaRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isProTablet = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const location = useLocation();
  const { obj } = location.state ?? {};

  const user = useSelector((state) => state.user.user)

  const dispatch = useDispatch();
  const [content, setContent] = useState({
    artId: obj?._id,
    artName: obj?.artName,
    artistName: obj?.artistName,
    statuss: obj?.statuss,
    userName: user?.name,
    userEmail: user?.email,
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [uploadAttempted, setUploadAttempted] = useState(false);
  // const [captchaVerified, setCaptchaVerified] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  // const [captchaToken, setCaptchaToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [waitingRes, setWaitingRes] = useState(false);

  const clear = () => {
    setContent({
      ...content,
      userName: "",
      userEmail: "",
      message: "",
    });
    setErrors({});
    // setCaptchaToken(null);
    setErrorMessage("");
    // setCaptchaVerified(false);
  };

  const validate = () => {
    const newErrors = {};
    if (!content.userName) newErrors.userName = "Your name is required";
    if (!content.userEmail) newErrors.userEmail = "Email address is required";
    return newErrors;
  };

  const submitButton = (token) => {
    // setCaptchaVerified(true);
    setShowSubmitButton(true);
    // setCaptchaToken(token);
    // setCaptchaToken("Invalid token");
  };



  const handleUpload = (e) => {
    e.preventDefault();
    dispatch(sendArtInquiryAsync({ ...content, token: '' }));
    dispatch(saveInquiryArt({ userData: user, artId: obj._id }))
    setUploadAttempted(true);
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setShowSubmitButton(false);
    setWaitingRes(true);
    // if (recaptchaRef.current) {
    //   recaptchaRef.current.reset();
    // }
    clear();
  };

  const status = useSelector((state) => state.art.status);
  const error = useSelector((state) => state.art.error);

  // console.log("error message: ", errorMessage.message);
  // console.log("error message status: ", status);

  useEffect(() => {
    if (uploadAttempted && status === "succeeded") {
      setWaitingRes(false);
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 2000);
    } else if (uploadAttempted && status === "failed") {
      setErrorMessage(
        error.message || "Failed to send the message. Please try again."
      );
      setTimeout(() => setErrorMessage(""), 4000);
    }
  }, [status, uploadAttempted, error]);

  return obj ?
    (<>
      {/* <div className="mArt" style={{}}> */}
      {/* {showSuccess && (
        <div className="successMessage">
          Message sent successfully! Our team will respond to your inquiry soon.
        </div>
      )}
      {errorMessage && <div className="errorMessage">{errorMessage}</div>} */}
      {/* <div className="heading">

        </div> */}
      <div style={{ display: 'flex', width: '100%', paddingTop: (isMobile || isTablet) ? '2rem' : '2rem', paddingBottom: (isMobile || isTablet) ? '' : '2rem', flexDirection: (isMobile || isTablet) ? 'column' : 'row', gap: '1rem', justifyContent: 'center' }}>

        <div style={{
          display: 'flex', flexDirection: (isMobile || isTablet) ? 'column' : "column", justifyContent: 'center',
          paddingInline: (isMobile || isTablet) ? '1rem' : '', alignContent: 'center', alignItems: 'center'
          // paddingBottom: isMobile ? '' : '2rem',
          // gap: '1rem'
        }}>
          <div style={{ pointerEvents: 'none', maxWidth: isMobile ? '' : isTablet ? '80%' : isProTablet ? '35rem' : '50rem', height: isMobile ? "" : "80vh", justifyContent: 'center' }}>
            <CardMedia
              component="img"
              draggable="false"
              onDragStart={(e) => e.preventDefault()}
              style={{ height: "100%", objectFit: 'contain', }}
              image={obj.selectedFile}
              alt={`Image of ${obj.artName}`}
            />
          </div>
          <div style={{
            overflow: 'hidden',
            padding: '1rem',
            // backgroundColor: 'rgba(226, 223, 210, .5)',
            // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            // flexDirection: isMobile ? 'row' : 'column',
            gap: '1rem',
            justifyContent: (isMobile || isTablet) ? 'center' : 'center'
          }}>
            <div style={{ display: 'flex', gap: '.5rem', flexDirection: 'column' }}>
              <div style={{ fontSize: (isMobile || isTablet) ? '1.2rem' : '1.2rem' }}>{obj.artName}</div>
              {obj.size && <div style={{ fontSize: (isMobile || isTablet) ? '1rem' : '1rem' }}>Size: {obj.size}</div>}
              <div style={{ fontSize: (isMobile || isTablet) ? '1rem' : '1rem' }}>Artist: {obj.artistName}</div>
              {obj.statuss !== 'Available' &&
                <div style={{ fontSize: (isMobile || isTablet) ? '1.1rem' : '1.2rem', color: 'red' }}>Sold</div>}
              {/* {<div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div onClick={handleLike} style={{ alignItems: "center", display: 'flex', justifyContent: 'left', width: '4rem' }}>{isLike ? <FavoriteIcon sx={{ color: '#fd5c63', marginRight: '0.1rem' }} /> : <FavoriteBorderIcon sx={{ marginRight: '0.1rem', }} />} {obj.likeCount > 0 ? obj.likeCount : ''}</div>
                  <div onClick={handleSave} style={{ alignItems: "center", display: 'flex', justifyContent: 'left' }}>{isSave ? <BookmarkIcon sx={{}} /> : <BookmarkBorderIcon sx={{}} />} </div>
                </div>} */}
            </div>
            {/* {isMobile && type === 'art' &&
                <>
                  <div onClick={handleLike} style={{ alignItems: "center", display: 'flex', justifyContent: 'left' }}>{isLike ? <FavoriteIcon sx={{ color: '#fd5c63', marginRight: '0.1rem' }} /> : <FavoriteBorderIcon sx={{ marginRight: '0.1rem', }} />} {obj.likeCount > 0 ? obj.likeCount : ''}</div>
                  <div onClick={handleSave} style={{ alignItems: "center", display: 'flex', justifyContent: 'left' }}>{isSave ? <BookmarkIcon sx={{}} /> : <BookmarkBorderIcon sx={{}} />} </div></>} */}
            {/* <div style={{ alignContent: 'end' }}>

                <button style={{ backgroundColor: '#505050', fontSize: isMobile ? '1.1rem' : '1.2rem' }} onClick={moveToInquiry}>
                  Inquiry
                </button>
              </div> */}
            {/* </div> */}
          </div>

        </div >
        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', marginBottom: '2rem' }}>
          <form onSubmit={handleUpload} style={{ display: 'flex', justifyContent: 'center', width: isMobile ? "" : isTablet ? '50%' : "100%", padding: '1rem', }}>
            <div
              // className="container"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "100%",
                marginLeft: isMobile ? "" : ''
                // maxWidth: "600px",
              }}
            >
              <div
                // variant={isMobile ? "h6" : "h5"}
                // component="h5"
                className="title"
                style={{ justifyContent: 'center', display: 'flex', marginBottom: '1rem' }}
              >
                Enquiry Form
              </div>
              {/* <div> */}
              <div className="userName" style={{ alignItems: 'center' }}>

                <div className="textBody" >
                  Name:
                </ div>
                <TextField
                  sx={{ fontSize: isMobile ? '1.1rem' : '1.2rem' }}
                  size="small"
                  // label="Your Name"
                  id="inquiry"
                  value={content.userName}
                  onChange={(e) =>
                    setContent({ ...content, userName: e.target.value })
                  }
                  required
                  error={!!errors.userName}
                  helperText={errors.userName}
                />
              </div>

              <div className="userEmail" style={{ alignItems: 'center' }}>
                < div className="textBody" >
                  Email:
                </ div>
                <TextField
                  sx={{ fontSize: isMobile ? '1.1rem' : '1.2rem' }}
                  type="email"
                  size="small"
                  // label="Your Email"
                  id="inquiry"
                  value={content.userEmail}
                  onChange={(e) =>
                    setContent({ ...content, userEmail: e.target.value })
                  }
                  required
                  error={!!errors.userEmail}
                  helperText={errors.userEmail}
                />
              </div>

              <div
                className="message"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                < div className="textBody" >
                  Message:
                </ div>
                <TextField
                  multiline
                  rows={5}
                  sx={{ height: "7rem", width: "100%", fontSize: isMobile ? '1.1rem' : '1.2rem' }}
                  size="small"
                  // label="Inquiry Message"
                  id="inquiryMessage"
                  value={content.message}
                  onChange={(e) =>
                    setContent({ ...content, message: e.target.value })
                  }
                  required
                />
              </div>

              <div className="uploadContainer" style={{ display: "flex", width: '100%', }}>

                <button type="submit" className='textBody' style={{ marginTop: '1rem', width: '7rem', backgroundColor: '#505050' }}>
                  Submit
                </button>
                <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                  {waitingRes &&
                    <div style={{ width: '100%', display: 'flex', outline: "none", fontFamily: 'lobster', fontSize: '1.2rem', justifyContent: 'center', alignItems: "center" }}>
                      <div className="dot-container">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    </div>}
                  {status !== 'idle' ?
                    <div className='textBody' style={{ padding: '0.2rem', textAlign: 'center', alignContent: 'center', alignItems: 'center' }}>
                      {showSuccess ?
                        <div>Message sent successfully!</div>
                        :
                        errorMessage ?
                          <div>{errorMessage}</div>
                          : <></>}
                    </div> : <></>
                  }
                </div>
              </div>

              {/* <div
              className="captchaContainer"
              style={{
                marginTop: "1rem",
                // padding: "5px",
                // marginLeft: isMobile ? "0.4rem" : "",
                // border: "solid",
              }}
            >
              {/* <ReCAPTCHA
                sitekey={sitekey}
                onChange={submitButton}
                style={{
                  // border: "solid yellow",
                  paddingLeft: "5px",
                }}
                ref={recaptchaRef}
              />
              {showSubmitButton && (
                // <Button type="submit" variant="outlined" color="primary">
                //   Send
                // </Button>
                <button style={{ backgroundColor: '#505050', }}>
                  Send
                </button>
              )}
              {waitingRes && <div style={{ display: 'flex', outline: "none", fontSize: isMobile ? '1.1rem' : '1.2rem', justifyContent: 'center', alignItems: "center" }}>
                <div className="dot-container">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>}
            </div>
            {/* </div> 
          </div> */}
            </div>
          </form>
        </div>
      </div >
      {/* </div > */}
    </>)
    : (<div>Please go back to art page</div>)
}

export default Inquiry;


{/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
            {/* <img
              style={{
                // border: "solid",
                objectFit: 'contain',
                width: "100%",
                height: "100%",
                marginBottom: '0.5rem'
              }}
              src={obj?.selectedFile}
              alt={obj?.artName}
            /> */}

// <div style={{ maxWidth: isMobile ? '' : '50rem', maxHeight: isMobile ? "" : "80vh", justifyContent: 'center' }}>
//   <CardMedia
//     component="img"
//     style={{ height: "100%", objectFit: 'contain' }}
//     image={obj.selectedFile}
//     alt={`Image of ${obj.artName}`}
//   />
// </div>

// <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//   <div>
//     < div sx={{ fontSize: isMobile ? '1.1rem' : '1.3rem' }} component="h6">
//       {obj?.artName}
//     </ div>
//     {/* </div> */}

//     {/* <div className="artistName"> */}
//     {obj?.size && < div sx={{ fontSize: isMobile ? '1.1rem' : '1.3rem' }} component="h6">
//       Size: {obj?.size}
//     </ div>}
//     < div sx={{ fontSize: isMobile ? '1.1rem' : '1.18rem' }} component="h6">
//       Artist: {obj?.artistName}
//     </ div>
//     {obj?.statuss !== 'Available' && < div sx={{ color: 'red', fontSize: isMobile ? '1.1rem' : '1.3rem' }} component="h6">
//       Sold
//     </ div>}
//   </div>
//   {/* </div> */}

//   {/* <div className="fieldSet"> */}

// </div>
//    </div >