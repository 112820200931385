import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../redux/userSlice";
import '../../global.css';

function SideBar({ setShowSideBar }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const encryptedPassword = localStorage.getItem("encryptedPassword");
  const userToken = useSelector((state) => state.user.jwt)
  const location = useLocation();
  const { pathname, search, hash } = location;
  // const handleLogout = () => {
  //   setShowSideBar(false);
  //   localStorage.removeItem("encryptedPassword");
  //   navigate("/"); // Redirect to login page or any other route after logout
  //   setShowSideBar(false);
  // };

  const handleClickUser = () => {
    setShowSideBar(false);
    if (userToken) {
      dispatch(logout())
      localStorage.removeItem("encryptedPassword");
      navigate("/");
    } else {
      navigate("/user/auth/signin"); // Redirect to login page or any other route after logout
      setShowSideBar(false);
    }
  }

  return (
    <div
      style={{
        backgroundColor: "white",
        width: "100%",
        height: "100%",
        // fontFamily: 'lobster',
      }}
    >
      <button
        style={{ padding: '2rem', color: 'black', fontSize: '2rem' }}
        onClick={() => {
          setShowSideBar(false);
        }}
        className="close-button"
      >
        ×
      </button>
      < div style={{ paddingLeft: '2rem' }}>
        < div>
          < div
            style={{ outline: 'none', cursor: "pointer", color: pathname === '/' ? '#770000' : 'black', paddingBottom: '1rem' }}
            onClick={() => {
              navigate("/");
              setShowSideBar(false);
            }}
            className="title"
          >
            Home
          </ div>
        </ div>

        < div>
          < div
            style={{ outline: 'none', cursor: "pointer", color: pathname === '/arts' ? '#770000' : 'black', paddingBottom: '1rem' }}
            onClick={() => {
              navigate("/arts");
              setShowSideBar(false);
            }}
            className="title"
          >
            Art
          </ div>
        </ div>
        < div>
          < div
            style={{ outline: 'none', cursor: "pointer", color: pathname === '/artists' ? '#770000' : 'black', paddingBottom: '1rem' }}
            onClick={() => {
              navigate("/artists");
              setShowSideBar(false);
            }}
            className="title"
          >
            Artist
          </ div>
        </ div>
        < div>
          < div
            style={{ outline: 'none', cursor: "pointer", color: pathname === '/about' ? '#770000' : 'black', paddingBottom: '1rem' }}
            onClick={() => {
              navigate("/about");
              setShowSideBar(false);
            }}
            className="title"
          >
            About
          </ div>
        </ div>
        {userToken && < div>
          < div
            style={{ outline: 'none', cursor: "pointer", color: pathname === '/user/profile' ? '#770000' : 'black', paddingBottom: '1rem' }}
            onClick={() => {
              navigate("/user/profile");
              setShowSideBar(false);
            }}
            className="title"
          >
            Profile
          </ div>
        </ div>}
        {/* {!encryptedPassword && (
          < div>
            < div
              style={{ cursor: "pointer", color: 'black' }}
              onClick={() => {
                navigate("/auth");
                setShowSideBar(false);
              }}
            >
              Admin Access
            </ div>
          </ div>
        )}
        {encryptedPassword && (
          < div>
            < div
              style={{ cursor: "pointer", color: 'black' }}
              onClick={() => {
                navigate("/addartvalidation");
                setShowSideBar(false);
              }}
            >
              Add Art & Artist
            </ div>
          </ div>
        )}
        {encryptedPassword && (
          < div>
            < div style={{ cursor: "pointer", color: 'black' }} onClick={handleLogout}>
              Admin exit
            </ div>
          </ div>
        )} */}
        {userToken ? < div>
          < div style={{ outline: 'none', cursor: "pointer", color: 'black' }} className="title" onClick={handleClickUser}>
            Sign Out
          </ div>
        </ div> : < div>
          < div style={{ outline: 'none', cursor: "pointer", color: pathname === '/user/auth/signin' ? '#770000' : 'black' }} className="title" onClick={handleClickUser}>
            Sign In
          </ div>
        </ div>}
      </  div>
    </div>
  );
}

export default SideBar;
