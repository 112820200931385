import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useTheme,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "./ConfirmationModal";
import "./styles.css";
import { deleteArtAsync } from "../../../redux/ArtSlice";
import { deleteArtistAsync } from "../../../redux/ArtistSlice";
import { followArtist, likeArt } from "../../../redux/userSlice";
import '../../../global.css';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

function ArtCopy({ obj, type }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLaptop = useMediaQuery(theme.breakpoints.up("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const user = useSelector((state) => state.user.user)

  const isFollowing = (type !== 'art' && type !== 'profileArts') && user?.followingArtist?.includes(obj._id)

  const handleFollow = (event) => {
    event.preventDefault(); // Prevent default behavior if this is a form submission or link click
    event.stopPropagation(); // Stop the event from bubbling up

    if (user) {
      dispatch(followArtist({ userData: user, artistId: obj._id }))
    } else {
      navigate('/user/auth/signin')
    }
  };


  const isLike = (type === 'art' || type === 'profileArts') && user?.likeArts?.includes(obj._id)

  const handleLike = (event) => {
    event.preventDefault(); // Prevent default behavior if this is a form submission or link click
    event.stopPropagation();

    if (user) {
      dispatch(likeArt({ userData: user, artId: obj._id }))
    } else {
      navigate('/user/auth/signin')
    }
  }

  const handleUpdateClick = () => {
    navigate(`/addartvalidation`, { state: { obj, type } });
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    setShowModal(true);
    handleMenuClose();
  };

  const handleConfirmDelete = () => {
    if (type === 'art' || type === 'profileArts') {
      dispatch(deleteArtAsync(obj._id));
    } else if (type === 'artist') {
      dispatch(deleteArtistAsync(obj._id));
    }
    setShowModal(false);
  };

  const handleCancelDelete = () => {
    setShowModal(false);
  };

  const handleCardClick = () => {
    if (type === "artist" || type === 'followingArtist') {
      navigate(`/artists/artistinfo/${obj._id}`, { state: { obj, type } });
    } else if (type === "art" || type === 'profileArts') {
      navigate(`/arts/artinfo/${obj._id}`, { state: { obj, type } });
    }
  };

  const getName = (obj, type) => {
    return (type === "art" || type === 'profileArts') ? obj.artName : obj.artistName;
  };

  const getImage = (obj) => {
    return obj.selectedFile;
  };

  return (
    <>
      <div
        style={{

          position: "relative",
          // maxHeight: isMobile ? type === "artist" ? "18rem" : "13rem" : '18rem',
          width: isMobile ? '20rem' : isLargeScreen ? '30rem' : '20rem',
          outline: "none",
        }}
      >
        <div onClick={handleCardClick} style={{ outline: "none", cursor: "pointer", }}>

          <img
            style={{
              height: "100%", maxHeight: isMobile ? type === "artist" || type === "followingArtist" ? "15rem" : "10rem" : type === "artist" || type === "followingArtist" ? '22rem' : '18rem', borderRadius: '0.2rem',
              width: '100%', objectFit: 'cover', pointerEvents: 'none',
            }}
            draggable="false"
            onDragStart={(e) => e.preventDefault()}
            src={getImage(obj)}
            alt={`Image of ${getName(obj, type)}`}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0.2rem' }}>
          <div style={{ display: 'flex', alignContent: 'center', flexDirection: (type === "art" || type === 'profileArts') ? 'row' : 'row', flex: 1, gap: '0.2rem', alignItems: (type === "art" || type === 'profileArts') ? '' : '', justifyContent: 'center', }}>
            <div
              onClick={handleCardClick}
              className="title2"
              style={{
                flex: 1,
                fontFamily: 'arial',
                textAlign: (type === "art" || type === 'profileArts') ? 'left' : 'left',
                // fontSize: '1rem',
                alignContent: "center",
                outline: "none", cursor: "pointer"
              }}
            >
              {getName(obj, type)}
              {(type === "art" || type == 'profileArts') &&
                <><br />
                  <div style={{ fontSize: isMobile ? '0.6rem' : '0.8rem', marginTop: '0.2rem', color: 'grey' }}>BY {obj?.artistName}</div>
                </>}
            </div>
            {type !== 'art' && type !== 'profileArts' &&
              <div onClick={handleFollow} style={{ maxHeight: '0.9rem', color: 'grey', width: '5rem', fontSize: '0.8rem', textTransform: 'uppercase', cursor: "pointer", textAlign: 'center', padding: '0.1rem', border: '.01rem solid grey', display: 'flex', justifyContent: 'center' }}>
                {isFollowing ? 'Following' : 'Follow'}
              </div>}
          </div>
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignContent: "center",
            }}
          >
            {(type === "art" || type === 'profileArts') && obj.statuss === "Sold" && (
              <div
                className="title3"
                style={{
                  color: "red",
                  // fontFamily: 'lobster',
                  marginLeft: '0.2rem',
                  alignContent: "center",
                }}
              >
                Sold
              </div>
            )}
            {/* {(type === 'art' || type === 'profileArts') &&
               <div onClick={handleSave} style={{ alignItems: "center", display: 'flex', justifyContent: 'left' }}>{isSave ? <BookmarkIcon sx={{}} /> : <BookmarkBorderIcon sx={{}} />} </div>
              <div onClick={handleLike} style={{ alignItems: "center", display: 'flex', justifyContent: 'right', width: '3rem' }}>{isLike ? <FavoriteIcon sx={{ color: '#fd5c63', marginRight: '0.1rem' }} /> : <FavoriteBorderIcon sx={{ marginRight: '0.1rem', }} />} {obj.likeCount > 0 ? obj.likeCount : ''}</div>} */}
            {localStorage.getItem("encryptedPassword") && (
              <div style={{ alignContent: "center", }}>
                <MoreVertIcon onClick={handleMenuClick} />
                <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
                  <MenuItem onClick={handleUpdateClick}>Update</MenuItem>
                  <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </div>
      </div >
      <ConfirmationModal
        open={showModal}
        type={type}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </>
  );
}

export default ArtCopy;
