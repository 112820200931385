import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Button, useMediaQuery, useTheme } from "@mui/material";
import ArtCopy from "./art/ArtCopy";
import ScrollToTop from "../scrollToTop/ScrollToTop";
import CenteredCircularProgress from "../circularProgress/CenteredCircularProgress";
import LoadingSpinner from '../circularProgress/LoadingSpinner'
import "./artsCopy.css";
import "../../global.css"

function ArtsCopy({ name, data, type, onArtFilterToggle, showAllArts, isLoading }) {
  const theme = useTheme();

  // const [isLoading, setLoading] = useState(false)

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));


  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickType = () => {
    // onArtFilterToggle()
    // setLoading(true)
  }

  // useEffect(() => {
  //   setLoading(false)
  // }, [showAllArts])

  return !data.length ? (<>
    {type === 'followingArtist' ?
      <div style={{ width: '100%', textAlign: 'center', padding: '1rem', fontSize: '1.2rem' }}>
        You are not following any artist.
      </div> :
      type === 'profileArts' ?

        <div style={{ width: '100%', padding: '1rem' }}><div style={{ textAlign: 'center', fontSize: '1.2rem' }}>
          You have not {showAllArts ? "Enquiried for" : "Saved"} any art.
        </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', cursor: 'pointer', fontSize: '1.1rem', textDecoration: 'underline' }} onClick={onArtFilterToggle}>
            check {(showAllArts ? "saved" : "Enquiried")} arts.
          </div></div>
        :
        <div style={{ height: '80vh', width: '100%', display: 'flex', outline: "none", fontSize: '1.2rem', justifyContent: 'center', alignItems: "center" }}>
          <div className="dot-container">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div>}</>
  ) : (
    <div style={{ padding: '1rem', width: '100%' }}>
      <div style={{ display: 'flex' }}>
        {/* <div className="title" style={{ marginLeft: isMobile ? '45%' : '48%', }}>
            {name}
          </div> */}
        {type !== 'followingArtist' && <div style={{ cursor: 'pointer', outline: "none", alignContent: 'center', textDecoration: 'underline', marginLeft: 'auto', marginRight: isMobile ? '3rem' : '4rem' }} onClick={handleClickType}>
          {/* {type === 'profileArts' ? (showAllArts ? "Saved" : "Enquired") : (showAllArts ? "Featured" : "All")} */}
          <div ref={dropdownRef} style={{ position: 'relative', display: 'inline-block', marginTop: '0.25rem' }}>
            {<>
              <div onClick={toggleDropdown} className="title2" style={buttonStyle}>
                SORT BY
              </div>
              {isOpen && (
                <ul style={menuStyle}>
                  {type === 'art' && <> <li style={itemStyle}>
                    <div onClick={() => {
                      onArtFilterToggle('Available')
                      setIsOpen(false);
                      // setShowSideBar(false);
                    }} className="title2" style={{
                      paddingInlineEnd: '1rem', cursor: 'pointer',
                      color: name === 'Available' ? '#770000' : ''
                    }}>Available</div>
                  </li>
                    <li style={itemStyle}>
                      <div onClick={() => {
                        onArtFilterToggle('Sold')
                        setIsOpen(false);
                        // setShowSideBar(false);
                      }} className="title2" style={{
                        paddingInlineEnd: '1rem', cursor: 'pointer',
                        color: name === 'Sold' ? '#770000' : ''
                      }}>Sold</div>
                    </li>
                    <li style={itemStyle}>
                      <div onClick={() => {
                        onArtFilterToggle('Ascending')
                        setIsOpen(false);
                        // setShowSideBar(false);
                      }} className="title2" style={{
                        paddingInlineEnd: '1rem', cursor: 'pointer',
                        color: name === 'Ascending' ? '#770000' : ''
                      }}>Ascending</div>
                    </li>
                    <li style={itemStyle}>
                      <div onClick={() => {
                        onArtFilterToggle('Descending')
                        setIsOpen(false);
                        // setShowSideBar(false);
                      }} className="title2" style={{
                        paddingInlineEnd: '1rem', cursor: 'pointer',
                        color: name === 'Descending' ? '#770000' : ''
                      }}>Descending</div>
                    </li></>}
                  {type === 'artist' && <>
                    <li style={itemStyle}>
                      <div onClick={() => {
                        onArtFilterToggle('Ascending')
                        setIsOpen(false);
                        // setShowSideBar(false);
                      }} className="title2" style={{
                        paddingInlineEnd: '1rem', cursor: 'pointer',
                        color: name === 'Ascending' ? '#770000' : ''
                      }}>Ascending</div>
                    </li>
                    <li style={itemStyle}>
                      <div onClick={() => {
                        onArtFilterToggle('Descending')
                        setIsOpen(false);
                        // setShowSideBar(false);
                      }} className="title2" style={{
                        paddingInlineEnd: '1rem', cursor: 'pointer',
                        color: name === 'Descending' ? '#770000' : ''
                      }}>Descending</div>
                    </li>
                  </>}
                  {type === 'profileArts' && <>
                    <li style={itemStyle}>
                      <div onClick={() => {
                        onArtFilterToggle('Saved')
                        setIsOpen(false);
                        // setShowSideBar(false);
                      }} className="title2" style={{
                        paddingInlineEnd: '1rem', cursor: 'pointer',
                        color: name === 'SAVED' ? '#770000' : ''
                      }}>Saved</div>
                    </li>
                    <li style={itemStyle}>
                      <div onClick={() => {
                        onArtFilterToggle('Enquired')
                        setIsOpen(false);
                        // setShowSideBar(false);
                      }} className="title2" style={{
                        paddingInlineEnd: '1rem', cursor: 'pointer',
                        color: name === 'ENQUIRED' ? '#770000' : ''
                      }}>Enquired</div>
                    </li>
                  </>}


                </ul>
              )}</>}</div>
        </div>}
      </div>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: isMobile ? '2rem' : isTablet ? '2rem' : "5rem",
          justifyContent: "center",
          marginBottom: "2rem",
          marginTop: "1rem",
          outline: "none",
          // border: 'solid'
        }}
      >
        {data.map((item) => (
          <div
            // className="mapDiv"
            style={{
              // width: isMobile ? "10rem" : "15rem",

              // marginBottom: "2rem",
              // marginTop: "1rem",
              // border: 'solid red',
              display: 'flex',
              alignItems: 'end'
            }}
            key={item._id}
          >
            <ArtCopy obj={item} type={type} />
          </div>
        ))}
      </Box>
      {isLoading &&
        (<div style={{ padding: '1rem', outline: "none", width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {/* <div className="title" style={{ marginLeft: isMobile ? '45%' : '48%' }}>
          {name}
        </div> */}
            <div style={{ width: '100%', display: 'flex', outline: "none", fontSize: isMobile ? '1.1rem' : '1.2rem', justifyContent: 'center', alignItems: "center" }}>
              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          </div>
        </div>)}
      <ScrollToTop />
    </div >
  )
}

const buttonStyle = {
  // paddingBottom: '1rem',
  // marginRight: '2rem',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  color: 'black',
  paddingLeft: '0.5rem',
  // paddingBottom: '1rem',
  // border: '1px solid #ccc',
};

const menuStyle = {
  listStyleType: 'none',
  // marginRight: '2rem',
  // marginRight: '2rem',
  // border: 'solid',
  // paddingLeft: '0.5rem',
  padding: '0',
  margin: '0',
  marginTop: '1rem',
  position: 'absolute',
  top: '100%',
  // left: '5',
  zIndex: '10',
  backgroundColor: 'white', // No background color
};

const itemStyle = {
  // paddingTop: '1rem',
  paddingLeft: '0.5rem',
  paddingBottom: '0.5rem',
  cursor: 'pointer',
};

export default ArtsCopy;
