import React, { useEffect, useState } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Button, Typography, useMediaQuery, useTheme, CardMedia } from "@mui/material";
import "./artInfo.css";
import { useDispatch, useSelector } from "react-redux";
import { likeArt, saveArt } from "../../../redux/userSlice";
import { fetchArtInfo } from "../../../redux/ArtSlice";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CenteredCircularProgress from "../../circularProgress/CenteredCircularProgress";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';


function ArtInfo() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isProTablet = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const dispatch = useDispatch();

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // const { obj, type } = location.state;

  const obj = useSelector((state) => state.art.artInfo)
  const statusArtInfo = useSelector((state) => state.art.statusArtInfo)
  const message = useSelector((state) => state.art.message)

  const type = 'art';

  useEffect(() => {
    dispatch(fetchArtInfo(id))
  }, [id])


  const user = useSelector((state) => state.user.user)
  const isLike = obj && user?.likeArts?.includes(obj._id)

  const handleLike = (event) => {
    event.preventDefault(); // Prevent default behavior if this is a form submission or link click
    event.stopPropagation();

    if (user) {
      dispatch(likeArt({ userData: user, artId: obj._id }))
    } else {
      navigate('/user/auth/signin')
    }
  }

  const isSave = obj && user?.saveArtId?.includes(obj._id)

  const handleSave = (event) => {
    event.preventDefault(); // Prevent default behavior if this is a form submission or link click
    event.stopPropagation();

    if (user) {
      dispatch(saveArt({ userData: user, artId: obj._id }))
    } else {
      navigate('/user/auth/signin')
    }
  }

  const moveToInquiry = () => {
    if (type === "art" && user) {
      navigate(`/arts/artinfo/${id}/inquiry`, { state: { obj, type } });
    } else {
      navigate('/user/auth/signin')
    }
  };

  const moveToUpdate = () => {
    if (type === "art") {
      navigate(`/addartvalidation`, { state: { obj, type } });
    }
  };

  return (
    <>
      {statusArtInfo === 'idle' || statusArtInfo === 'loading' ?
        <div style={{ height: '80vh', width: '100%', display: 'flex', outline: "none", fontSize: isMobile ? '1.1rem' : '1.2rem', justifyContent: 'center', alignItems: "center" }}>
          <div className="dot-container">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div> :
        statusArtInfo === 'failed' ?
          <div style={{ textAlign: 'center', width: '100%', padding: '1rem', fontSize: isMobile ? '1.2rem' : '1.2rem' }}>{message}</div>
          :
          <div style={{
            display: 'flex', flexDirection: isMobile ? 'column' : "row", justifyContent: 'center',
            minHeight: '80vh', width: '100%', paddingInline: isMobile ? '1rem' : '',
            paddingTop: isMobile ? '' : '2rem', paddingBottom: isMobile ? '' : '2rem',
            gap: '1rem',
            // display: 'flex', border: 'solid red', paddingTop: isMobile ? '2rem' : '2rem', paddingBottom: isMobile ? '' : '2rem', flexDirection: isMobile ? 'column' : 'row', gap: '1rem', justifyContent: 'center'
          }}>
            <div style={{ width: isMobile ? '' : '20%' }}></div>
            <div style={{ flexGrow: 1, textAlign: 'center', pointerEvents: 'none', maxWidth: isMobile ? '' : isTablet ? '30rem' : isProTablet ? '35rem' : '50rem', height: isMobile ? "" : "80vh", justifyContent: 'center' }}>
              <CardMedia
                component="img"
                draggable="false"
                onDragStart={(e) => e.preventDefault()}
                style={{ height: "100%", objectFit: 'contain' }}
                image={obj.selectedFile}
                alt={`Image of ${obj.artName}`}
              />
            </div>
            <div style={{
              overflow: 'hidden',
              width: isMobile ? "" : "20%", padding: '1rem',
              // backgroundColor: 'rgba(226, 223, 210, .5)',
              // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              flexDirection: isMobile ? 'row' : 'column',
              gap: '1rem',
              justifyContent: isMobile ? 'space-between' : 'center'
            }}>
              <div style={{ display: 'flex', gap: '.5rem', flexDirection: 'column' }}>
                <div className="title" style={{ fontSize: isMobile ? '1.2rem' : '1.2rem' }}>{obj.artName}</div>
                {obj.size && <div className="textBody" style={{}}>Size: {obj.size}</div>}
                {obj.year && <div className="textBody" style={{}}>Year: {obj.year}</div>}
                {obj.medium && <div className="textBody" style={{}}>Medium: {obj.medium}</div>}
                <div className="textBody" style={{}}>Artist: {obj.artistName}</div>
                {obj.statuss !== 'Available' &&
                  <div style={{ color: 'red' }}>Sold</div>}

              </div>
              {/* {isMobile && type === 'art' &&
                <>
                  <div onClick={handleLike} style={{ alignItems: "center", display: 'flex', justifyContent: 'left' }}>{isLike ? <FavoriteIcon sx={{ color: '#fd5c63', marginRight: '0.1rem' }} /> : <FavoriteBorderIcon sx={{ marginRight: '0.1rem', }} />} {obj.likeCount > 0 ? obj.likeCount : ''}</div>
                  <div onClick={handleSave} style={{ alignItems: "center", display: 'flex', justifyContent: 'left' }}>{isSave ? <BookmarkIcon sx={{}} /> : <BookmarkBorderIcon sx={{}} />} </div></>} */}
              <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignContent: 'end' }}>

                <button style={{ backgroundColor: '#505050', height: isMobile ? '2.5rem' : '' }} onClick={moveToInquiry}>
                  Enquiry
                </button>
                {type === 'art' && <div style={{}}>
                  {/* <div onClick={handleLike} style={{ alignItems: "center", display: 'flex', justifyContent: 'left', width: '4rem' }}>{isLike ? <FavoriteIcon sx={{ color: '#fd5c63', marginRight: '0.1rem' }} /> : <FavoriteBorderIcon sx={{ marginRight: '0.1rem', }} />} {obj.likeCount > 0 ? obj.likeCount : ''}</div> */}
                  <div onClick={handleSave} style={{}}>{isSave ? <BookmarkIcon sx={{}} /> : <BookmarkBorderIcon sx={{}} />} </div>
                </div>}
              </div>
              {/* </div> */}
            </div>

          </div >}
    </>
  );
}

export default ArtInfo;
